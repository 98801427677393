import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { Button, JanitorialModal, ServicesList } from '../components';

import styles from './services.module.scss';

const Janitorial = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { allContentfulJanitorialPackages, contentfulJanitorialPage } = useStaticQuery(graphql`
    {
      allContentfulJanitorialPackages(sort: {order: ASC, fields: contentfulid}) {
        nodes {
          name
          description {
            description
          }
        }
      }
      contentfulJanitorialPage {
        title
        subtitle
      }
    }
  `);

  return (
    <>
      <main>
        {/* HERO */}
        <div className={`${styles.hero} ${styles.janitorial}`}>
          <div className="container">
            <h1 className="heading">{contentfulJanitorialPage.title}</h1>
            <h2 className="subheading">{contentfulJanitorialPage.subtitle}</h2>
            <Button
              label="Show prices"
              onClick={() => {
                setIsOpen(true);
                firebase.analytics().logEvent('open_janitorial_prices');
              }}
            />
          </div>
        </div>
        {/* Content */}
        <div className={styles.content}>
          <div className="container">
            <ServicesList data={allContentfulJanitorialPackages.nodes} />
          </div>
        </div>
      </main>

      <JanitorialModal open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Janitorial;
